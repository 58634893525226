<template>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app dark :color="$const.color.primary">
        <v-list dense>
            <template v-for="(item, i) in filteredByRoleItems">
                <v-divider v-if="item.border_top" :key="`divider${i}`" />
                <item :item="item" :key="i" />
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import Item from './Item.vue'

export default {
    name: 'Sidebar',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: { Item },
    data() {
        return {
            items: [
                { heading: 'Состояние', accessSections: ['license', 'app_update', ''] },
                { icon: 'mdi-home', text: 'Главная', href: '/' },
                { icon: 'mdi-lock-reset', text: 'Восстановление доступа', href: '/update-admin-password', accessSections: ['update_admin_password'] },
                { icon: 'mdi-shield-account', text: 'Лицензии', href: '/license', accessSections: ['license'] },
                { icon: 'mdi-square-rounded-badge-outline', text: 'Обновления приложения', href: '/app-update', accessSections: ['app_update'] },
                // { icon: 'mdi-square-rounded-badge-outline', text: 'Обновления контента', href: '/content-update' },
                { heading: 'Отчеты', accessSections: ['fioko_data_collection']},
                { icon: 'mdi-file-document-multiple-outline', text: 'Отчеты ФИС ОКО', href: '/fioko-data-collection', accessSections: ['fioko_data_collection'] },
                { icon: 'mdi-file-document-multiple-outline', text: 'Успеваемость', href: '/performance', accessSections: ['performance'] },
                { heading: 'Управление', accessSections: ['group', 'user', 'calendar']},
                { icon: 'mdi-calendar', text: 'Календарь ВПР', href: '/calendar', accessSections: ['calendar'] },
                { icon: 'mdi-card-account-details-outline', text: 'Классы', href: '/group', accessSections: ['group'] },
                {
                    icon: 'mdi-account-arrow-left-outline', text: 'Пользователи', href: '/user/info', accessSections: ['user'],
                    children: [
                        { icon: 'mdi-circle-small', text: 'Администраторы ОО', href: '/user?role=admins', accessSections: ['user'] },
                        { icon: 'mdi-circle-small', text: 'Преподаватели', href: '/user?role=teacher', accessSections: ['user'] },
                        { icon: 'mdi-circle-small', text: 'Учащиеся', href: '/user?role=student', accessSections: ['user'] }
                    ]
                }
            ]
        }
    },
    computed: {
        filteredByRoleItems () {
            return this.items.filter(item => !item.accessSections ?
                                        true :
                                        item.accessSections.some(section => this.$store.getters['user/userCan'](section, 'view')) // User can access to one on item from 'accessSections'
                                    )
        },
        drawer: {
            get: function(){
                return this.visible;
            },
            set: function(newValue){
                this.$emit('update:visible', newValue)
            }   
        },
    }
}
</script>